ngb-accordion .card-header {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

ngb-accordion .card-body {
  padding: 0px;
  margin: 0px;
}


#ngb-live.visually-hidden {
  display: none;
}