.row-compact {
  margin-left: 0;
  margin-right: 0;
}

  .row-compact > [class^="col"] {
    padding-left: 0;
    padding-right: 0;
  }


.multi-line {
  white-space: pre-line;
}


.right-radius-only {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}


.left-radius-only {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}


/*

*/

.dropdown-menu {
  float: right !important;
  /* position: inherit !important;*/
}

ngb-typeahead-window {
  right: unset !important;
}

.rounded-0-right {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-0-left {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.border-3 {
  border-width: 3px !important;
}

.ltr{
  direction:ltr
}
  .ltr select {
    padding-top: 0.1875rem;
  }


input[type="number"] {
  direction: ltr;
  text-align: right;
}