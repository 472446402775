ngx-bootstrap-multiselect.selected .btn{
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

ngx-bootstrap-multiselect.selected .btn:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    border-top-color: rgb(30, 126, 52);
    border-right-color: rgb(30, 126, 52);
    border-bottom-color: rgb(30, 126, 52);
    border-left-color: rgb(30, 126, 52);
}

ngx-bootstrap-multiselect.selected .btn:not(:disabled):not(.disabled).active, ngx-bootstrap-multiselect.selected .btn:not(:disabled):not(.disabled):active, .show>ngx-bootstrap-multiselect.selected .btn.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}