.tooltip-text-info .tooltip-inner {
    background-color: #17a2b8!important;
}

.tooltip-text-info .arrow::before {
    border-top-color: #17a2b8!important;
}





.popoverW-fit-content {
    max-width: fit-content;
}
