.modal-backdrop.fade {
  opacity: 0.5;
}

.modal-open .modal {
  opacity: 1;
}



ngb-modal-window.modal {
  z-index: 1060;
}