.tag-input input {
  width: 100% !important
}

.autocomplate-dropdown-menu-full-width .dropdown-menu {
  min-width: 100%
}


.autocomplate-dropdown-menu-h150-scrollable .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}


.autocomplate-dropdown-menu-h350-scrollable .dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}

.tag-input-padding-0 tag-input, .tag-input-padding-0 tag-input > div {
  padding: 0px 0px 0px 0px !important;
  /* border-width: 0px !important;*/
}

  .tag-input-padding-0 tag-input > div {
    border-width: 0px !important;
  }

  .tag-input-padding-0 tag-input input {
    background-color: transparent !important;
  }
