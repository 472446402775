/* Change the white to any color ;) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}


.has-float-label {
  display: block;
  position: relative;
}

  .has-float-label label, .has-float-label > span.label {
    position: absolute;
    top: 0;
    cursor: text;
    font-size: 85%;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    top: -.5em;
    right: 0.75rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
    background-color: #ffffff
  }

    .has-float-label label::after, .has-float-label > span::after {
      content: " ";
      display: block;
      position: absolute;
      background: white;
      height: 2px;
      top: 50%;
      left: -.2em;
      right: -.2em;
      z-index: -1;
    }

  .has-float-label .form-control::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
  }

  .has-float-label .form-control::-moz-placeholder {
    opacity: 1;
    transition: all .2s;
  }

  .has-float-label .form-control:-ms-input-placeholder {
    opacity: 1;
    transition: all .2s;
  }

  .has-float-label .form-control::placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
  }

  .has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
  }

  .has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0;
  }

  .has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0;
  }

  .has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }

  .has-float-label .form-control:placeholder-shown:not(:focus) + * {
    font-size: 125%;
    opacity: .5;
    top: 0.4em;
  }

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

  .input-group .has-float-label .form-control {
    width: 100%;
    border-radius: 0.25rem;
  }

  .input-group .has-float-label:not(:last-child), .input-group .has-float-label:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  .input-group .has-float-label:not(:first-child), .input-group .has-float-label:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

.float-label-loading {
  position: relative;
  float: left;
  top: -34px;
}

labeled-textbox.compact > .form-group {
  margin-bottom: 7px;

}
labeled-textbox.compact input {
 padding:2px 6px 1px 1px;

}

.no-margin .form-group {
  margin:0 0 0 0 !important;  

}
.no-margin input {
  margin:0 0 0 0 !important;
}


labeled-textbox.compact .has-float-label .form-control:placeholder-shown:not(:focus) + * {
  top: 0.15em;
}


h3 .has-float-label .form-control:placeholder-shown:not(:focus) + * {
  top: -0.1em;
}

h3 .has-float-label > i {
  font-size: 1em !important;
  margin-bottom:-1em;
}