.page-item:last-child .page-link {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0px;
}



.page-item:first-child .page-link {
  margin-left: -1px;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}