.upload-zone .content {
  height: inherit !important;
}

  .upload-zone .content .cancel-file {
    cursor: pointer;
  }

    .upload-zone .content .cancel-file:hover {
      color: black;
    }



